import Btn from '@componentPrimitives/Btn';
import BlockHeader from '@components/BlockHeader';
import { BlockContainer } from '@components/Blocks';
import CardGrid from '@components/CardGrid';
import { ArticleCard } from '@components/EntryCard';
import { createBlock } from '@lib/features/blocks/blocks';
import { useAppLink, useView } from '@lib/store';
import { useTranslations } from 'next-intl';

const LatestArticles = createBlock<'blocks_latestArticles_BlockType'>(({ heading, content }) => {
  const t = useTranslations('article');
  const indexLink = useAppLink('articleIndex');
  const indexHref = indexLink?.uri || undefined;

  const latestArticles = useView()?.viewExtras?.latestArticles ?? [];

  return (
    <BlockContainer>
      <BlockHeader
        {...{
          variant: 'split',
          heading,
          HeadingProps: { variant: 'h1' },
          content,
          cta: indexHref && (
            <Btn href={indexHref} variant="text" endIcon="arrowRight">
              {t('indexLink')}
            </Btn>
          ),
        }}
      />
      <CardGrid sm={6} md={4} lg={3} items={latestArticles} Component={ArticleCard} />
    </BlockContainer>
  );
});

export default LatestArticles;
